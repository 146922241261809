

















































@import "src/assets/style/global"
.container
  padding: 0px 0 0px 0
  width: 80%
  max-width: 500px
  margin: 0 auto
  box-sizing: border-box
.divider
  color: rgb(white,0.3)
  border-style: solid
li,ul
  list-style: none
  padding: 0
.list li
  display: flex
  margin-bottom: 10px
  color: white
a
  text-decoration: none
.article-title
  flex: 1
  display: flex
  align-items: center
  color: white
.article-time
  color: hsla(0,0%,100%,.7)
.article-more-button
  margin-top: 16px
  display: flex
  color: white
  font-size: 18px
  background-color: rgba(white,.2)
  border-radius: 8px
  margin: 0 auto
  width: 128px
  height: 48px
  align-items: center
  justify-content: center
.article-more-alabel
  margin-top: 16px
  display: flex
  color: white
  font-size: 18px
